<template>
  <div id="dashboard-content">
    <h4 class="mb-4">Pembinaan</h4>
    <div v-if="notFound" class="text-center my-5">
      <h5>Anda belum terdaftar dalam event apapun</h5>
    </div>
    <div v-else class="card border-0 shadow-sm p-3">
      <div v-if="loading" class="text-center">
        <div
          class="spinner-border"
          style="width: 3rem; height: 3rem;"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <di v-else>
        <div class="input-group mb-3  w-50">
          <div class="input-group-prepend">
            <span class="input-group-text bg-white border-right-0">
              <i class="fa fa-search"></i>
            </span>
          </div>
          <input
            type="text"
            class="form-control border-left-0 px-0"
            placeholder="Cari pembinaan"
            v-model="keyword"
            @change="filteredData"
          />
        </div>

        <div class="row">
          <div v-if="filteredData.length == 0" class="col-12">
            <EmptyState
              title="Pencarian tidak ditemukan"
              subtitle="Silahkan cari pembinaan dengan kata kunci lain"
            />
          </div>
          <div
            v-else
            v-for="(training, index) of filteredData"
            :key="index"
            class="col-lg-6 mb-3"
          >
            <div class="card h-100 mb-3 border-0 shadow">
              <img
                class="card-img-top"
                style="height: 300px;object-fit: cover;"
                :src="training.training_event.cover"
                alt="Card image cap"
              />
              <div class="card-body">
                <div class="d-flex mb-3">
                  <div
                    v-if="training.status == 'pending'"
                    class="bg-secondary-50 px-3 py-1 mr-3 rounded-pill text-secondary"
                  >
                    Menunggu Persetujuan
                  </div>
                  <div
                    v-if="
                      training.status == 'approved' ||
                        training.status == 'attended'
                    "
                    class="bg-success-50 px-3 py-1 mr-3 rounded-pill text-success"
                  >
                    Pendaftaran Diterima
                  </div>
                  <div
                    v-if="training.status == 'canceled'"
                    class="bg-danger-50 px-3 py-1 mr-3 rounded-pill text-danger"
                  >
                    Tidak lolos seleksi
                  </div>
                  <div class="d-flex align-items-center">
                    <i class="fa fa-users mr-2"></i>
                    <span>0 kuota Tersisa</span>
                  </div>
                </div>
                <h5 class="card-title m-0">
                  <router-link
                    :to="'/pelaku/pembinaan/detail?id=' + training.training_id"
                  >
                    {{ training.training_event.title || "-" }}
                  </router-link>
                </h5>
                <div class="text-muted mb-3">
                  {{ training.training_event.subtitle }}
                </div>
                <div
                  class="row justify-content-center align-items-center p-3 m-0 bg-grey-100 rounded"
                >
                  <div class="col-lg-6 mb-2">
                    <div class="d-flex align-items-center">
                      <i
                        class="fa fa-lg fa-calendar-check mr-2 text-primary"
                      ></i>
                      <div class="d-flex flex-column">
                        <small>
                          {{ trainingDate(training.training_event.start) }}
                        </small>
                        <small class="text-muted">
                          {{ trainingTime(training.training_event.start) }}
                          -
                          {{ trainingTime(training.training_event.end) }}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-2">
                    <div class="d-flex align-items-center">
                      <i class="fa fa-lg fa-map-pin mr-2 text-primary"></i>
                      <small
                        v-if="training.training_event.event_type == 'online'"
                        class="text-muted"
                        >Daring</small
                      >
                      <a
                        v-else
                        :href="training.training_event.location_address"
                        class="text-muted"
                        >Luring</a
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    training.status != 'canceled' &&
                      training.status != 'pending'
                  "
                  class="mt-4 d-flex"
                >
                  <router-link
                    v-if="training.training_event.status == 'finish'"
                    :to="'/sertifikat/' + training.ticketcode"
                    class="btn btn-sm btn-outline-secondary mr-2 w-100 mr-2"
                  >
                    <i class="fa fa-certificate fa-fw mr-1"></i>Lihat Sertifikat
                  </router-link>
                  <router-link
                    :to="'/ticket/' + training.ticketcode"
                    class="btn btn-sm btn-secondary mr-2 w-100 mr-2 text-white"
                  >
                    <i class="fa fa-ticket-alt fa-fw mr-1"></i>Lihat Tiket
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </di>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { BASE_URL } from "../../store";
import EmptyState from "@/components/global/dashboard/EmptyState.vue";

moment.locale("id");

export default {
  name: "Pembinaan",
  data() {
    return {
      trainings: [],
      notFound: false,
      keyword: "",
      showNotFound: false,
      loading: false,
    };
  },
  methods: {
    trainingDate(start) {
      return moment(start).format("LL");
    },
    trainingTime(start) {
      return moment(start)
        .format("LT")
        .replace(".", ":");
    },
    setNotFound() {
      this.setNotFound = true;
    },
  },
  computed: {
    timeToday: function() {
      const day = moment(this.$store.state.today).format("dddd");
      return day + ", " + moment(this.$store.state.today).format("LL");
    },
    filteredData: function() {
      return this.trainings.filter((training) => {
        return training.training_event.title
          .toLowerCase()
          .includes(this.keyword.toLowerCase());
      });
    },
  },
  created() {
    const { email } = JSON.parse(localStorage.getItem("user"));
    this.loading = true;
    axios
      .post(`${BASE_URL}api/training/tickets?email=${email}&limit=10&page=1`)
      .then((res) => {
        let statusUser = res.data.status;
        if (statusUser == "failed") {
          this.notFound = true;
        } else {
          this.trainings = res.data.data;
        }
      })
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
  },
  beforeCreate() {
    const logged_in = localStorage.getItem("user");
    if (!logged_in) {
      this.$router.push("/login");
    }
  },
  components: { EmptyState },
};
</script>
