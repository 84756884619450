var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"dashboard-content"}},[_c('h4',{staticClass:"mb-4"},[_vm._v("Pembinaan")]),(_vm.notFound)?_c('div',{staticClass:"text-center my-5"},[_c('h5',[_vm._v("Anda belum terdaftar dalam event apapun")])]):_c('div',{staticClass:"card border-0 shadow-sm p-3"},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_vm._m(0)]):_c('di',[_c('div',{staticClass:"input-group mb-3 w-50"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text bg-white border-right-0"},[_c('i',{staticClass:"fa fa-search"})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],staticClass:"form-control border-left-0 px-0",attrs:{"type":"text","placeholder":"Cari pembinaan"},domProps:{"value":(_vm.keyword)},on:{"change":_vm.filteredData,"input":function($event){if($event.target.composing)return;_vm.keyword=$event.target.value}}})]),_c('div',{staticClass:"row"},[(_vm.filteredData.length == 0)?_c('div',{staticClass:"col-12"},[_c('EmptyState',{attrs:{"title":"Pencarian tidak ditemukan","subtitle":"Silahkan cari pembinaan dengan kata kunci lain"}})],1):_vm._l((_vm.filteredData),function(training,index){return _c('div',{key:index,staticClass:"col-lg-6 mb-3"},[_c('div',{staticClass:"card h-100 mb-3 border-0 shadow"},[_c('img',{staticClass:"card-img-top",staticStyle:{"height":"300px","object-fit":"cover"},attrs:{"src":training.training_event.cover,"alt":"Card image cap"}}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex mb-3"},[(training.status == 'pending')?_c('div',{staticClass:"bg-secondary-50 px-3 py-1 mr-3 rounded-pill text-secondary"},[_vm._v(" Menunggu Persetujuan ")]):_vm._e(),(
                    training.status == 'approved' ||
                      training.status == 'attended'
                  )?_c('div',{staticClass:"bg-success-50 px-3 py-1 mr-3 rounded-pill text-success"},[_vm._v(" Pendaftaran Diterima ")]):_vm._e(),(training.status == 'canceled')?_c('div',{staticClass:"bg-danger-50 px-3 py-1 mr-3 rounded-pill text-danger"},[_vm._v(" Tidak lolos seleksi ")]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fa fa-users mr-2"}),_c('span',[_vm._v("0 kuota Tersisa")])])]),_c('h5',{staticClass:"card-title m-0"},[_c('router-link',{attrs:{"to":'/pelaku/pembinaan/detail?id=' + training.training_id}},[_vm._v(" "+_vm._s(training.training_event.title || "-")+" ")])],1),_c('div',{staticClass:"text-muted mb-3"},[_vm._v(" "+_vm._s(training.training_event.subtitle)+" ")]),_c('div',{staticClass:"row justify-content-center align-items-center p-3 m-0 bg-grey-100 rounded"},[_c('div',{staticClass:"col-lg-6 mb-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fa fa-lg fa-calendar-check mr-2 text-primary"}),_c('div',{staticClass:"d-flex flex-column"},[_c('small',[_vm._v(" "+_vm._s(_vm.trainingDate(training.training_event.start))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.trainingTime(training.training_event.start))+" - "+_vm._s(_vm.trainingTime(training.training_event.end))+" ")])])])]),_c('div',{staticClass:"col-lg-6 mb-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fa fa-lg fa-map-pin mr-2 text-primary"}),(training.training_event.event_type == 'online')?_c('small',{staticClass:"text-muted"},[_vm._v("Daring")]):_c('a',{staticClass:"text-muted",attrs:{"href":training.training_event.location_address}},[_vm._v("Luring")])])])]),(
                  training.status != 'canceled' &&
                    training.status != 'pending'
                )?_c('div',{staticClass:"mt-4 d-flex"},[(training.training_event.status == 'finish')?_c('router-link',{staticClass:"btn btn-sm btn-outline-secondary mr-2 w-100 mr-2",attrs:{"to":'/sertifikat/' + training.ticketcode}},[_c('i',{staticClass:"fa fa-certificate fa-fw mr-1"}),_vm._v("Lihat Sertifikat ")]):_vm._e(),_c('router-link',{staticClass:"btn btn-sm btn-secondary mr-2 w-100 mr-2 text-white",attrs:{"to":'/ticket/' + training.ticketcode}},[_c('i',{staticClass:"fa fa-ticket-alt fa-fw mr-1"}),_vm._v("Lihat Tiket ")])],1):_vm._e()])])])})],2)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }