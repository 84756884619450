<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center py-5"
  >
    <img src="@/assets/dashboard/icon/EmptyState.png" class="w-25" alt="" />
    <h6>{{ title }}</h6>
    <div class="text-muted">{{ subtitle }}</div>
  </div>
</template>

<script>
export default {
  props: ["title", "subtitle"],
};
</script>
